<template>
  <base-layout fixed-width>
    <div class="home-page">
      <div class="summary">
        <div class="user">
          <span>{{ $t('home.welcome') }}</span>
          <div>{{ user.data.organization_name }}</div>
        </div>
        <div class="tip hz">
          <div class="info">
            <div class="title">{{ $t('home.docs.title') }}</div>
            <div class="description">{{ $t('home.docs.desc') }}</div>
          </div>
          <button class="button primary">{{ $t('home.docs.action') }}</button>
        </div>

        <div class="tips">
          <div class="tip vr a">
            <div class="info">
              <div class="title">{{ $t('home.rating.title') }}</div>
              <router-link :to="{ name: 'archive' }">
                <span>{{ $t('home.rating.action') }}</span>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17.3 8.995l-3.187-3.177-1.058 1.063 1.376 1.371L.7 8.251v1.5l13.729.001-1.366 1.371 1.062 1.059 3.177-3.187z" fill="#000"/></svg>
              </router-link>
            </div>
          </div>
          <div class="tip vr b">
            <div class="info">
              <div class="title">{{ $t('home.help.title') }}</div>
              <a href="#">
                <span>{{ $t('home.help.action') }}</span>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17.3 8.995l-3.187-3.177-1.058 1.063 1.376 1.371L.7 8.251v1.5l13.729.001-1.366 1.371 1.062 1.059 3.177-3.187z" fill="#000"/></svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="news">
        <div v-if="majorTasksComputed.length" class="ix-mb-14">
          <div class="title">{{ $t('home.major_tasks') }}</div>
          <notification-item
            v-for="notification in majorTasksComputed"
            :key="notification.id"
            :data="notification"
            hide-info
          />
        </div>
        <div>
          <div class="title">{{ $t('home.notifications') }}</div>
          <notification-item
            v-for="notification in lastNotifications"
            :key="notification.id"
            :data="notification"
          />
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import { mapState } from 'vuex'
import BaseLayout from '@/components/layout/BaseLayout.vue'
import NotificationItem from '@/components/layout/NotificationItem.vue'

export default {
  components: { BaseLayout, NotificationItem },
  created() {
    this.$store.dispatch('User/getLastNotifications')
    this.$store.dispatch('User/getMajorTasks')
  },
  computed: {
    ...mapState('User', ['lastNotifications', 'user', 'majorTasks']),
    majorTasksComputed() {
      return this.majorTasks.map((task, i) => ({
        id: i,
        body: task.task_text,
        type: task.obj,
        object_id: task.object_id
      }))
    }
  },
};
</script>
