import Vue from "vue"

export default {
  namespaced: true,
  state: {
    organizationsList: [],
    calculationMethods: [],
    periodicityList: [],
    organizationTypes: [],
  },
  mutations: {
    SET_ORGANIZATIONS_LIST(state, organizations) {
      state.organizationsList = organizations
    },
    SET_CALCULATION_METHODS(state, methods) {
      state.calculationMethods = methods
    },
    SET_PERIODICITY_LIST(state, list) {
      state.periodicityList = list
    },
    SET_ORGANIZATION_TYPES(state, types) {
      state.organizationTypes = types
    },
  },
  actions: {
    getOrganizationsList({ commit }) {
      commit('SET_ORGANIZATIONS_LIST', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/organizations/', {
          params: {
            page_size: 999999,
          }
        }).then(response => {
          commit('SET_ORGANIZATIONS_LIST', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    getOrganizationTypes({ commit }) {
      commit('SET_ORGANIZATION_TYPES', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/organization_types/', {
          params: {
            page_size: 999999,
          }
        }).then(response => {
          commit('SET_ORGANIZATION_TYPES', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    getCalculationMethods({ commit }) {
      commit('SET_CALCULATION_METHODS', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/indicator_calculation_method/', {
          params: {
            page_size: 999999,
          }
        }).then(response => {
          commit('SET_CALCULATION_METHODS', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    getPeriodicityList({ commit }) {
      commit('SET_PERIODICITY_LIST', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/indicator_periodicity/', {
          params: {
            page_size: 999999,
          }
        }).then(response => {
          commit('SET_PERIODICITY_LIST', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
  }
}