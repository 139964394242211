<template>
  <div class="base-layout">
    <Header />
    <left-sidebar />
    
    <div class="layout-page">
      <breadcrumbs-menu
        v-if="breadcrumbs"
        :items="breadcrumbs"
      />
      <div class="page-content" :class="{ fixed: fixedWidth }">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue"
import LeftSidebar from "./LeftSidebar.vue"
import BreadcrumbsMenu from "./BreadcrumbsMenu.vue"

export default {
  props: {
    breadcrumbs: null,
    fixedWidth: Boolean
  },
  components: { Header, LeftSidebar, BreadcrumbsMenu }
}
</script>