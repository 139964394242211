import Vue from "vue"

export default {
  namespaced: true,
  state: {
    requestionList: [],
    requestionHistory: [],
    requestionDetails: null,
    subindexList: [],
    indicatorList: [],
    indicatorDetails: null,
    criterionList: [],
    proofList: [],
    lastOpenSubindexId: null,
    claimDetails: null,
    claimSubindexesForm:[],
    claimSubindexList: [],
    claimIndicatorsForm:[],
    claimIndicatorList: [],
    requestionExpertsList: []
  },
  mutations: {
    SET_REQUESTION_LIST(state, list) {
      state.requestionList = list
    },
    SET_REQUESTION_HISTORY(state, history) {
      state.requestionHistory = history
    },
    SET_REQUESTION_DETAILS(state, details) {
      state.requestionDetails = details
    },
    SET_SUBINDEX_LIST(state, list) {
      state.subindexList = list.sort((a, b) => a.sub_index__order_number - b.sub_index__order_number)
    },
    SET_LAST_OPEN_SUBINDEX(state, id) {
      state.lastOpenSubindexId = id
    },
    SET_INDICATOR_LIST(state, {subindexId, list}) {
      const intSubindexId = parseInt(subindexId)
      state.indicatorList = [
        ...state.indicatorList.filter(indicator => indicator.subindexId !== intSubindexId),
        {
          subindexId: intSubindexId, 
          list: list.sort((a, b) => a.indicator__order_number - b.indicator__order_number)
        }
      ]
    },
    SET_INDICATOR_DETAILS(state, details) {
      state.indicatorDetails = details
    },
    SET_CRITERION_LIST(state, list) {
      state.criterionList = list.sort((a, b) => a.order_number - b.order_number)
    },
    SET_PROOF_LIST(state, list) {
      state.proofList = list
    },
    SET_CLAIM_DETAILS(state, details) {
      state.claimDetails = details
    },
    SET_CLAIM_SUBINDEXES_FORM(state, list) {
      state.claimSubindexesForm = list
    },
    SET_CLAIM_SUBINDEX_LIST(state, list) {
      state.claimSubindexList = list
    },
    DELETE_CLAIM_SUBINDEX(state, id) {
      state.claimSubindexList = state.claimSubindexList.filter(i => i.id !== id)
    },
    SET_CLAIM_INDICATORS_FORM(state, list) {
      state.claimIndicatorsForm = list
    },
    SET_CLAIM_INDICATORS_LIST(state, list) {
      state.claimIndicatorList = list
    },
    DELETE_CLAIM_INDICATORS(state, id) {
      state.claimIndicatorList = state.claimIndicatorList.filter(i => i.id !== id)
    },
    SET_REQUESTION_EXPERTS_LIST(state, list) {
      state.requestionExpertsList = list
    }
  },
  getters: {},
  actions: {
    // requestion actions
    getRequestionList({ commit }) {
      commit('SET_REQUESTION_LIST', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/requestion_archive/', {
          params: { page_size: 999999 }
        }).then(response => {
          commit('SET_REQUESTION_LIST', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    getRequestionDetails({ commit }, id) {
      commit('SET_REQUESTION_DETAILS', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`api/v1/processing/view/requisitions/${id}/`)
          .then(response => {
            commit('SET_REQUESTION_DETAILS', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    rejectRequestionScores(_, { id, payload }) {
      return new Promise((resolve, reject) => {
        Vue.axios.post(`api/v1/processing/action/requisitions/${id}/view/22/`, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    // history
    getRequestionHistory({ commit }, requestionId) {
      commit('SET_REQUESTION_HISTORY', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/requisitions_history/', {
          params: { page_size: 999999, requisition: requestionId }
        }).then(response => {
          commit('SET_REQUESTION_HISTORY', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    // subindex actions
    getSubindexList({ commit }, { requestion, filters }) {
      commit('SET_SUBINDEX_LIST', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/rating_sub_requisitions/', {
          params: {
            page_size: 999999,
            requisition: requestion,
            ...filters
          }
        }).then(response => {
          commit('SET_SUBINDEX_LIST', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    reduceSubindexScore(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post(`api/v1/processing/action/rating_sub_requisitions/${payload.sub_index}/view/9/`, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    // indicator actions
    getIndicatorList({ commit }, subindexId) {
      commit('SET_INDICATOR_LIST', { subindexId, list: [] })
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/rating_indicator_requisitions/', {
          params: {
            page_size: 999999,
            requisition_sub_index: subindexId
          }
        }).then(response => {
          commit('SET_INDICATOR_LIST', { subindexId, list: response.data.results })
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    getIndicatorDetails({ commit }, id) {
      commit('SET_INDICATOR_DETAILS', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`api/v1/processing/view/rating_indicator_requisitions/${id}/`)
          .then(response => {
            commit('SET_INDICATOR_DETAILS', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    setIndicatorScore(_, payload) {
      return new Promise((resolve, reject) => {
        // requisition_indicator
        Vue.axios.post(`api/v1/processing/action/rating_indicator_criterion_requisitions/${payload.score}/view/2/`, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    // criterion actions
    getCriterionList({ commit }, { requestionIndicatorId, indicatorId }) {
      commit('SET_CRITERION_LIST', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/rating_indicator_criterion_requisitions/', {
          params: {
            page_size: 999999,
            requestion_indicator: requestionIndicatorId,
            indicator: indicatorId
          }
        }).then(response => {
          commit('SET_CRITERION_LIST', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    // proofs actions
    getProofList({ commit }, indicatorId) {
      commit('SET_PROOF_LIST', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/requisition_proofs/', {
          params: {
            page_size: 999999,
            requisition_indicator: indicatorId
          }
        }).then(response => {
          commit('SET_PROOF_LIST', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    createProof(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/processing/crud/requisition_proofs/', payload, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    deleteProof(_, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.delete(`api/v1/processing/crud/requisition_proofs/${id}/`)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    // claims actions
    createClaim(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/processing/crud/claims/', payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    getClaimDetails({ commit }, claimId) {
      commit('SET_CLAIM_DETAILS', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`api/v1/processing/view/claims/${claimId}/`)
          .then(response => {
            commit('SET_CLAIM_DETAILS', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    getClaimSubindexesForm({ commit }, requisition) {
      commit('SET_CLAIM_SUBINDEXES_FORM', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/claim_subindexes_form/', {
          params: { page_size: 999999, requisition },
        }).then(response => {
          commit('SET_CLAIM_SUBINDEXES_FORM', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    getClaimSubindexList({commit}, params) {
      commit('SET_CLAIM_SUBINDEX_LIST', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/claim_sub_indexes/', {
          params: { page_size: 999999, ...params },
        }).then(response => {
          commit('SET_CLAIM_SUBINDEX_LIST', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    createClaimSubindex(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/processing/crud/claim_sub_indexes/', payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    deleteClaimSubindex({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.delete(`api/v1/processing/crud/claim_sub_indexes/${id}/`)
          .then(response => {
            resolve(response.data)
            commit('DELETE_CLAIM_SUBINDEX', id)
          })
          .catch(error => reject(error.response))
      })
    },
    updateClaimSubindex(_, { payload, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/processing/crud/claim_sub_indexes/${id}/`, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    getClaimIndicatorsForm({ commit }, requisition) {
      commit('SET_CLAIM_INDICATORS_FORM', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/claim_indicators_form/', {
          params: { page_size: 999999, requisition_sub_index__requisition: requisition },
        }).then(response => {
          commit('SET_CLAIM_INDICATORS_FORM', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    getClaimIndicatorList({commit}, params) {
      commit('SET_CLAIM_INDICATORS_LIST', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/claim_indicators/', {
          params: { page_size: 999999, ...params },
        }).then(response => {
          commit('SET_CLAIM_INDICATORS_LIST', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    createClaimIndicator(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/processing/crud/claim_indicators/', payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    deleteClaimIndicator({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.delete(`api/v1/processing/crud/claim_indicators/${id}/`)
          .then(response => {
            resolve(response.data)
            commit('DELETE_CLAIM_INDICATORS', id)
          })
          .catch(error => reject(error.response))
      })
    },
    updateClaimIndicator(_, { payload, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/processing/crud/claim_indicators/${id}/`, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    //refs
    getRequestionExpertsList({ commit }, id) {
      commit('SET_REQUESTION_EXPERTS_LIST', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/rating_sub_requisitions_experts/', {
          params: {
            page_size: 999999,
            requisition: id
          }
        }).then(response => {
          commit('SET_REQUESTION_EXPERTS_LIST', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
  },
}