import Vue from "vue"

export default {
  namespaced: true,
  state: {
    eventsList: [],
    eventsData: {},
    calendar: []
  },
  mutations: {
    SET_EVENTS_LIST(state, list) {
      state.eventsList = list
    },
    SET_EVENTS_DATA(state, data) {
      state.eventsData = data
    },
    DELETE_EVENTS(state, id) {
      state.eventsList = state.eventsList.filter(o => o.id !== id)
    },
    PUBLISH_EVENTS(state, id) {
      state.eventsList = state.eventsList.map(event => {
        if (event.id === id) {
          return { ...event, status: 'published' }
        } else return event
      })
    },
    CANCEL_EVENTS(state, id) {
      state.eventsList = state.eventsList.map(event => {
        if (event.id === id) {
          return { ...event, status: 'cancelled' }
        } else return event
      })
    },
    SET_CALENDAR(state, list) {
      state.calendar = list
    },
  },
  getters: {},
  actions: {
    getEventsList({ commit }) {
      commit('SET_EVENTS_LIST', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/events/', {
          params: {
            page_size: 999999,
          }
        }).then(response => {
          commit('SET_EVENTS_LIST', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    getEventsData({ commit }, id) {
      commit('SET_EVENTS_DATA', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`api/v1/processing/view/events/${id}/`)
          .then(response => {
            commit('SET_EVENTS_DATA', response.data.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    createEvents(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/processing/crud/events/', payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    updateEvents(_, { payload, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/processing/crud/events/${id}/`, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    deleteEvents({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.delete(`api/v1/processing/crud/events/${id}/`)
          .then(response => {
            resolve(response.data)
            commit('DELETE_EVENTS', id)
          })
          .catch(error => reject(error.response))
      })
    },
    publish({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.post(`api/v1/processing/action/events/${id}/view/10/`)
          .then(response => {
            resolve(response.data)
            commit('PUBLISH_EVENTS', id)
          })
          .catch(error => reject(error.response))
      })
    },
    cancel({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.post(`api/v1/processing/action/events/${id}/view/11/`)
          .then(response => {
            resolve(response.data)
            commit('CANCEL_EVENTS', id)
          })
          .catch(error => reject(error.response))
      })
    },
    getCalendar({ commit }, year) {
      commit('SET_CALENDAR', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/calendar/', {
          params: {
            page_size: 999999,
            event_date__year: year
          }
        }).then(response => {
          commit('SET_CALENDAR', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
  },
}