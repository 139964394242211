import auth from "@/auth"

export default (_to, _from, next) => {
  const isAuth = auth.isAuth()

  if (isAuth) {
    next()
  } else {
    next({
      name: 'login'
    })
  }
}