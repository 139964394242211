<template>
  <div
    class="form-control select"
    :class="{
      'focused': isFocused,
      'disabled': disabled,
      'has-error': error
    }"
  >
    <select
      :type="type"
      :value="computedValue"
      :disabled="disabled"
      v-bind="$attrs"
      @change="onInput"
      @focus="onFocus"
      @blur="onBlur"
    >
      <option 
        :value="value" 
        selected
        disabled>
        {{ placeholder }}
      </option>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :selected="option.value === computedValue"
      >{{ option.label }}</option>
    </select>
    <div class="icon">
      <img src="@/assets/icons/select.svg">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: [Number, String],
    type: {
      type: String,
      default: "text"
    },
    options: Array,
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false
    },
    error: Boolean
  },
  data() {
    return {
      newValue: this.value,
      isFocused: false
    }
  },
  methods: {
    onInput(e) {
      this.computedValue = e.target.value;
    },
    onFocus() {
      this.isFocused = true
      this.$emit("focus")
    },
    onBlur() {
      this.isFocused = false
      this.$emit("blur")
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value(value) {
      this.newValue = value
    }
  }
}
</script>