<template>
  <Dropdown 
    fit-width 
    top-offset="5"
    ref="dropdown"
    @open="onFocus"
    @close="onBlur"
    :disabled="disabled"
  >
    <template #trigger>
      <div 
        class="form-control select custom"
        :class="{
          'focused': isFocused,
          'disabled': disabled,
          'has-error': error
        }"
      >
        <div class="custom-input" :class="{ 'selected': selectedOption }">
          {{ selectedOption?.label || placeholder }}
        </div>
        <div class="icon"><img src="@/assets/icons/select.svg"></div>
      </div>
    </template>
    <div class="select-dropdown">
      <div class="search-wrapper" v-if="searchable">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.652 11.593a6.001 6.001 0 10-1.059 1.059c.01.011.02.023.032.034l3.182 3.181a.75.75 0 001.06-1.06l-3.182-3.182a.771.771 0 00-.033-.032zm-1.558-6.862a4.5 4.5 0 11-6.363 6.364 4.5 4.5 0 016.363-6.364z" fill="#000"/></svg>
        <input
          ref="search"
          type="text" 
          placeholder="Искать..." 
          v-model="search" 
        />
      </div>
      <div class="options">
        <div 
          class="option"
          v-for="option in filteredOptions"
          :key="option.value"
          @click="onSelect(option.value)"
        >
          {{ option.label }}
        </div>
      </div>
    </div>
  </Dropdown>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue';

export default {
  props: {
    value: [Number, String],
    type: {
      type: String,
      default: "text"
    },
    searchable: Boolean,
    options: Array,
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false
    },
    error: Boolean
  },
  components: { Dropdown },
  data() {
    return {
      newValue: this.value,
      isFocused: false,
      search: ''
    }
  },
  methods: {
    onSelect(value) {
      this.computedValue = value;
      this.$refs.dropdown.away()
    },
    onFocus() {
      this.isFocused = true
      if (this.searchable) {
        this.$nextTick(() => {
          this.$refs['search'].focus();
        })
      }
      this.$emit("focus")
    },
    onBlur() {
      this.isFocused = false
      this.$emit("blur")
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit("input", value);
      },
    },
    filteredOptions() {
      const query = this.search.toLocaleLowerCase()
      return this.options.filter(option => option.label.toLocaleLowerCase().includes(query))
    },
    selectedOption() {
      if (!this.computedValue) return false
      return this.options.find(option => option.value == this.computedValue)
    }
  },
  watch: {
    value(value) {
      this.newValue = value
    }
  }
}
</script>