import Vue from "vue"

const initialState = {
  user: {
    user: null,
    token: null,
    data: {
      role: 'guest'
    },
    fontSize: 100
  },
  notifications: [],
  lastNotifications: [],
  notificationsUnread: 0,
  majorTasks: [],
  mainRating: null
}

export default {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    CHANGE_ROLE(state, role) {
      state.user.data.role = role
      state.user.user.role = role
    },
    CHANGE_LANGUAGE(state, language) {
      state.user.data.language = language
      state.user.user.language = language
    },
    SET_FONT_SIZE(state, size) {
      state.user.fontSize = size
    },
    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications
      state.notificationsUnread = 0
    },
    SET_LAST_NOTIFICATIONS(state, notifications) {
      state.lastNotifications = notifications
    },
    SET_NOTIFICATIONS_UNREAD(state, unread) {
      state.notificationsUnread = unread
    },
    SET_MAJOR_TASKS(state, tasks) {
      state.majorTasks = tasks
    },
    SET_MAIN_RATING(state, id) {
      state.mainRating = id
    }
  },
  getters: {
    isAuth(state) {
      return !!state.user.token
    },
    isCoordinator(state) {
      return state.user.data.role === 'coordinator'
    },
    isApplicant(state) {
      return state.user.data.role === 'applicant'
    },
    isExpert(state) {
      return state.user.data.role === 'expert'
    },
  },
  actions: {
    changeRole({ commit }, role) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch('api/v1/auth/set/role/', { role }).then((response) => {
          commit('CHANGE_ROLE', role)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    changeLanguage({ commit }, language) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch('api/v1/auth/set/role/', { language }).then((response) => {
          commit('CHANGE_LANGUAGE', language)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    changePassword(_, password) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch('api/v1/auth/change_password/', password).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    login({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/auth/login/', credentials).then((response) => {
          commit('SET_USER', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('SET_USER', { ...initialState.user })
        resolve()
      })
    },
    getNotificationsUnread({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/notification/unread-count/').then((response) => {
          commit('SET_NOTIFICATIONS_UNREAD', response.data.count)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    getNotifications({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/notification/list/read-all/', {
          params: { page_size: 48 }
        }).then((response) => {
          commit('SET_NOTIFICATIONS', response.data.results)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    getLastNotifications({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_LAST_NOTIFICATIONS', [])
        Vue.axios.get('api/v1/notification/list/', {
          params: { page_size: 5 }
        }).then((response) => {
          commit('SET_LAST_NOTIFICATIONS', response.data.results)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    getMajorTasks({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_MAJOR_TASKS', [])
        Vue.axios.get('api/v1/processing/raw/get_major_tasks/').then((response) => {
          commit('SET_MAJOR_TASKS', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    getMainRating({ commit, state }) {
      const userId = state.user.data.id
      return new Promise((resolve, reject) => {
        Vue.axios.get(`api/v1/processing/raw/get_main_rating/?user_id=${userId}`).then((response) => {
          commit('SET_MAIN_RATING', response.data[0].id)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    }
  },
}