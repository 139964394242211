import { locales, i18n } from '@/i18n'
const defaultLocale = locales.find(locale => locale.default)

const i18n_required = {
  validate(value) {
    if (value !== null && value[defaultLocale.key] !== '') return true
    return false
  },
  computesRequired: true,
  message: (_, values) => i18n.t('validations.required', values)
}

const not_null = {
  validate(value) {
    if (value !== null) return true
    return false
  },
  computesRequired: true,
  message: (_, values) => i18n.t('validations.required', values)
}

const i18n_min = {
  validate(value, { min }) {
    if (value[defaultLocale.key]?.length >= min) return true
    return false
  },
  params: ['min'],
  message: (_, values) => i18n.t('validations.str_min', values)
}

const positive_number = {
  validate(value) {
    if (parseInt(value) >= 0) return true
    return false
  },
  message: (_, values) => i18n.t('validations.positive_number', values)
}

const str_required = {
  validate(value) {
    if (typeof value === 'string' && value.length) return true
    return false
  },
  computesRequired: true,
  message: (_, values) => i18n.t('validations.required', values)
}

const file_required = {
  validate(value) {
    if (value?.length) return true
    return false
  },
  computesRequired: true,
  message: (_, values) => i18n.t('validations.required', values)
}

const str_min = {
  validate(value, { min }) {
    if (typeof value === 'string' && value.length >= min) return true
    return false
  },
  params: ['min'],
  message: (_, values) => i18n.t('validations.str_min', values)
}

const num_min = {
  validate(value, { min }) {
    if (typeof value === 'number' && value >= min) return true
    return false
  },
  params: ['min'],
  message: (_, values) => i18n.t('validations.num_min', values)
}

const url = {
  validate(value) {
    const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);
    if (value.match(regex)) return true
    return false
  },
  message: (_, values) => i18n.t('validations.url', values)
}

export default { 
  i18n_required, 
  not_null, 
  i18n_min, 
  positive_number,
  str_required,
  str_min,
  num_min,
  url,
  file_required
}