import Datepicker from './Datepicker'
import Select from './Select'
import SelectCustom from './SelectCustom'
import Input from './Input'
import Textarea from './Textarea'
import ColorPicker from './ColorPicker'
import Checkbox from './Checkbox'

export default {
  install(Vue) {
    Vue.component('form-input', Input)
    Vue.component('form-textarea', Textarea)
    Vue.component('form-select', Select)
    Vue.component('form-custom-select', SelectCustom)
    Vue.component('form-datepicker', Datepicker)
    Vue.component('color-picker', ColorPicker)
    Vue.component('form-checkbox', Checkbox)
  }
}