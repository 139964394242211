<template>
  <div class="left-sidebar">
    <div class="section-top">
      <ul>
        <li>
          <router-link :to="{ name: 'home' }" class="menu-item">
            <div class="wrapper">
              <img src="@/assets/icons/dashboard.svg" />
              <span class="name">{{ $t('routes.home') }}</span>
            </div>
            <!-- <span class="badge">12</span> -->
          </router-link>
        </li>
        <!-- <template v-if="mainRating">
          <li v-if="$store.getters['User/isApplicant']">
            <router-link :to="{ name: 'requestion', params: { id: mainRating } }" class="menu-item">
              <div class="wrapper">
                <img src="@/assets/icons/folder.svg" />
                <span class="name">{{ $t('routes.rating_detail') }}</span>
              </div>
            </router-link>
          </li>
          <li v-else>
            <router-link :to="{ name: 'rating', params: { id: mainRating } }" class="menu-item">
              <div class="wrapper">
                <img src="@/assets/icons/folder.svg" />
                <span class="name">{{ $t('routes.rating_detail') }}</span>
              </div>
            </router-link>
          </li>
        </template> -->
        <li>
          <router-link :to="{ name: 'archive' }" class="menu-item">
            <div class="wrapper">
              <img src="@/assets/icons/archive.svg" />
              <span class="name">{{ $t('routes.archive') }}</span>
            </div>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'events' }" class="menu-item">
            <div class="wrapper">
              <img src="@/assets/icons/events.svg" />
              <span class="name">{{ $t('routes.events') }}</span>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="section-bottom">
      <ul>
        <li>
          <a class="menu-item" @click="logout">
            <div class="wrapper">
              <img src="@/assets/icons/exit.svg" />
              <span class="name">{{ $t('interface.logout') }}</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  methods: {
    logout() {
      this.$auth.logout()
    }
  },
  // created() {
  //   this.$store.dispatch('User/getMainRating')
  // },
  // computed: {
  //   ...mapState('User', ['mainRating'])
  // }
}
</script>