<template>
  <div>
    <ul class="breadcrumbs">
      <li v-for="(item, n) in breadcrumbs" :key="n">
        <router-link :to="item.path" v-if="item.path">
          {{ item.label || $t(`routes.${item.name}`) }}
        </router-link>
        <a v-else>{{ item.label || $t(`routes.${item.name}`) }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BreadcrumbsMenu",
  props: {
    items: Array
  },
  methods: {
    navigate(path) {
      if (typeof path === "string") {
        this.$router.push({name: path})
      } else if (typeof path === "object") {
        this.$router.push(path)
      }
    }
  },
  computed: {
    breadcrumbs() {
      return this.items.map(item => {
        if (typeof item.path === "string") {
          return { ...item, path: { name: item.path } }
        }
        return item
      }).filter(item => !item.hide)
    }
  }
}
</script>