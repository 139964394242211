export default {
  lang: "Русский",
  code: "RU",
  interface: {
    search: "Поиск",
    logout: "Выйти",
    settings: "Настройки",
    detail: "Подробнее",
    choose: "Выберите",
    back: "Назад",
    download: "загрузить",
    confirm_action: "Подтвердите действие",
    remove_attention_message: "Вы действительно хотите удалить данный объект со всем его содержимым? Данное действие нельзя отменить.",
    cancel: "Отменить",
    send: "Отправить",
    publish: "Опубликовать",
    submit: "Принять",
    save: "Сохранить",
    remove_object: "Удалить объект",
    type_note: "Введите примечание",
    ok: "Ок",
  },
  routes: {
    home: "Главная",
    archive: "Архив",
    rating_detail: "Индекс",
    sub_rating_detail: "Период",
    requestion_detail: "Заявка",
    indicator_detail: "Критерий",
    events: "Календарь событий",
    manageEvents: "Управление событиями",
  },
  user: {
    role: {
      coordinator: "Координатор",
      expert: "Эксперт",
      applicant: "Заявитель",
      security: "Сотрудник безопасности",
    }
  },
  home: {
    welcome: "Добро пожаловать",
    docs: {
      title: "Прочитайте руководство",
      desc: "Повысьте свою эффективность",
      action: "Открыть руководство"
    },
    rating: {
      title: "Мои индексы",
      action: "Перейти"
    },
    help: {
      title: "Есть вопросы?",
      action: "Служба поддержки"
    },
    major_tasks: "Важные задачи",
    notifications: "Последние события",
  },
  events: {
    manage: "Управление событиями",
    create: "Создать событие",
    created_at: "создано",
    info_type: "тип",
    cancel: "Отменить событие",
    cancel_attention: "Вы действительно хотите отменить это событие? Данное действие нельзя отменить.",
    type: {
      periodic: "Повторное событие",
      exact_date: "Разовое событие"
    },
    status: {
      draft: "Черновик",
      published: "Опубликован",
      cancelled: "Отменен",
    },
    period: {
      week: "Каждую неделю",
      month: "Каждый месяц",
      quarter: "Каждый квартал",
      "half-year": "Каждое полугодие",
      year: "Каждый год",
    }
  },
  archive: {
    create: "Новый индекс"
  },
  proof: {
    create: "Добавить подтверждение",
    proof: "Подтверждения",
  },
  indicator: {
    proofs: "подтверждения",
    criterions: "Ответы",
    criterions_and_proofs: "Ответы и подтверждения",
    file: "файл",
    link: "ссылка",
    criterions_count: "оценок",
    max_score: "максимальный балл",
    org_score: "самооценка",
    expert_score: "оценка эксперта",
    coordinator_score: "оценка координатора",
    periodicity: "периодичность",
    calculation_method: "метод расчета",
  },
  criterion: {
    create: "Добавить ответ",
    score: "балл",
    organization_choose: "Оценка заявителя",
    expert_choose: "Оценка эксперта",
    coordinator_choose: "Оценка координтора",
    reduce_score_modal: "Укажите причину изменения оценки",
  },
  history: {
    is_rejected: "Возвращен",
    author: "автор"
  },
  rating: {
    requestions: "заявок",
    created_at: "создан",
    published: "опубликовано",
    open: "прием заявок",
    evalution: "оценка экспертов",
    result_publish: "публикация результатов",
    clone: "Клонировать",
    is_main: 'Сделать основным',
    tabs: {
      subindex: "Индикаторы",
      requestions: "Заявки",
      history: "История",
      report: "Сводный отчет",
      members: "Заявители",
      subrating: "Мониторинг",
    }
  },
  subindex: {
    indicators_count: "индикаторов",
    max_score: "максимальный балл",
    reduce_score: "балл понижения",
    current_score: "текущий балл",
    final_score: "финальный балл",
    create: "Добавить индикатор",
    org_score: "самооценка",
    expert_score: "оценка эксперта",
    coordinator_score: "оценка координатора",
    progress_by_applicant: "прогресс заявителя",
    progress_by_expert: "прогресс экспертов",
    reduce_score_modal: "Понизить оценку",
    annual_score: "Годовой балл",
    preliminary_score: "предварительный годовой балл",
    reduced_annual_score: "Годовой балл с учетом понижения",
  },
  report: {
    organization: "Организация",
    total_score: "Всего баллов",
    scores: "баллы"
  },
  requestion: {
    claim: "Претензия",
    created_at: "создан",
    published: "опубликован",
    total_score: "общий балл",
    progress_by_applicant: "прогресс заявителя",
    progress_by_expert: "прогресс экспертов",
    total_score: "общий балл",
    create_claim: "Создать претензию",
    status: "Статус заявки",
    reject_scores: "Отклонить оценки",
    tabs: {
      subindex: "Индикаторы",
      history: "История",
    },
    statuses: {
      all: "Все",
      "2": "Создан",
      "3": "Размещение заявки",
      "4": "Модерация",
      "5": "Оценка заявки",
      "6": "Модерация оценки",
      "7": "Заявка принята",
      "8": "Не отправлено",
      "9": "Оценка заявки принята",
      "10": "Подведение итогов",
      "11": "Закрыто",
      "13": "Отменен",
    }
  },
  settings: {
    locale: "Локализация",
    locale_desc: "Смена языка интерфейса",
    interface: "Интерфейс",
    interface_desc: "Общие настройки интерфейса",
    password: "Сменить пароль",
    password_desc: "Смена пароля",
    font_size: "Размер шрифта"
  },
  forms: {
    indicator: "Критерий",
    subindex: "Индикатор",
    score: "Оценка",
    order_number: "Порядковый №",
    claim: "Претензия",
    file: "Файл",
    link: "Ссылка",
    desc_placeholder: "Введите описание",
    name_placeholder: "Введите наименование",
    name: "Наименование",
    desc: "Описание",
    event_type: "Тип события",
    date: "Дата",
    days_of_warning: "Дней до события",
    repeat: "Повторять",
    notifications: "Уведомления",
    daily_notifications: "Ежедневные уведомления",
    calendar_color: "Цвет в календаре",
    text: "Текст",
    links: "Ссылки",
    files: "Файлы",
    confirm_types: "Типы подтверждения",
    organizations: "Организации",
    members: "Участники",
    year: "Отчетный период",
    index_type: "Тип индекса",
    open_date: "Начало приема заявок",
    close_date: "Конец приема заявок",
    evalution_open_date: "Начало оценки экспертов",
    evalution_close_date: "Конец оценки экспертов",
    result_publish_date: "Дата объявления результатов",
    reduce_score: "Понижающая оценка",
    short_name: "Аббревиатура",
    expert_organization: "Экспертная организация",
    can_reduced: "Разрешить понижение оценки",
    max_reduce_score: "Максимально возможное понижение",
    index_periodicity: "Периодичность",
    index_calculation_method: "Метод расчета",
  },
  validations: {
    required: "Это поле обязательно для заполнения.",
    str_min: "Поле должно содержать не менее {min} символов.",
    positive_number: "Число должно быть больше либо равно нулю.",
    num_min: "Число должно быть не меньше {min}.",
    url: "Укажите ссылку правильного формата.",
    file: "Вы не выбрали файл для загрузки.",
    at_least_one: "Выберите как минимум один пункт из списка.",
  },
  claim: {
    title: "Претензия",
    letter: "письмо с претензией",
    created_at: "создан",
    expert_answer: "Ответ {expert}",
    download_attachments: "загрузить ответ на претензию",
    attachments: "приложения",
    indicators: "Жалобы по критериям",
    indicator_create: "Добавить претензию к баллу критерия",
    subindexes: "Жалобы по баллам понижения",
    subindexes_create: "Добавить претензию к баллу понижения",
    answer: "Ответить",
    remove_answer: "Удалить ответ",
    org_score: "самооценка",
    expert_score: "оценка эксперта",
    reduce_score: "балл понижения",
    indicator_modal: "Претензия к баллу критерия",
    subindex_modal: "Претензия к баллу понижения",
    answer_modal: "Ответ на претензию",
    answer_modal_desc: "Заполните примечание и прикрепите письмо с ответом.",
  }
};