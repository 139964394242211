import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import User from './modules/user'
import Rating from './modules/rating'
import Requestion from './modules/requestion'
import Events from './modules/events'
import Reference from './modules/reference'
import Processing from './modules/processing'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    User,
    Rating,
    Reference,
    Processing,
    Requestion,
    Events
  },
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  plugins: [createPersistedState({
    key: 'INDEX_FRONT_APP',
    storage: window.localStorage
  })]
})
