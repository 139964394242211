<template>
  <div class="layout-header">
    <div class="header-left">
      <div class="logo">
        <a>
          <img src="@/assets/logo.svg" alt="Index" />
        </a>
      </div>
    </div>
    <div class="header-right">
      <div class="user-role">{{ $t('user.role')[user.data.role] }}</div>
      <Notifications />
      <user-menu />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Notifications from '@/components/layout/Notifications.vue'
import UserMenu from '@/components/layout/UserMenu.vue'

export default {
  components: { Notifications, UserMenu },
  computed: {
    ...mapState('User', ['user'])
  }
}
</script>