import Vue from "vue"

export default {
  namespaced: true,
  state: {
    ratingTypes: [],
    membersList: [],
    requestionsList: [],
    ratingHistory: [],
    ratingList: [],
    ratingDetails: null,
    ratingData: null,
    subindexList: [],
    subindexData: null,
    indicatorList: [],
    indicatorDetails: null,
    indicatorData: null,
    criterionList: [],
    criterionData: null,
    openSubindexIds: [],
    report: null,
    mainReport: null
  },
  mutations: {
    SET_TYPES(state, types) {
      state.ratingTypes = types
    },
    SET_MEMBERS_LIST(state, list) {
      state.membersList = list
    },
    SET_REQUESTIONS_LIST(state, list) {
      state.requestionsList = list
    },
    SET_RATING_HISTORY(state, history) {
      state.ratingHistory = history
    },
    SET_RATING_LIST(state, list) {
      state.ratingList = list
    },
    SET_RATING_DETAILS(state, details) {
      state.ratingDetails = details
    },
    SET_RATING_DATA(state, data) {
      state.ratingData = data
    },
    DELETE_RATING(state, id) {
      state.ratingList = state.ratingList.filter(o => o.id !== id)
    },
    SET_SUBINDEX_LIST(state, list) {
      state.subindexList = list.sort((a, b) => a.order_number - b.order_number)
    },
    SET_SUBINDEX_DATA(state, data) {
      state.subindexData = data
    },
    SET_OPEN_SUBINDEX(state, id) {
      state.openSubindexIds = [...state.openSubindexIds, id]
    },
    SET_CLOSE_SUBINDEX(state, id) {
      state.openSubindexIds = state.openSubindexIds.filter(sId => id !== sId)
    },
    DELETE_SUBINDEX(state, id) {
      state.subindexList = state.subindexList.filter(o => o.id !== id)
    },
    SET_INDICATOR_LIST(state, {subindexId, list}) {
      const intSubindexId = parseInt(subindexId)
      state.indicatorList = [
        ...state.indicatorList.filter(indicator => indicator.subindexId !== intSubindexId),
        {
          subindexId: intSubindexId, 
          list: list.sort((a, b) => a.order_number - b.order_number)
        }
      ]
    },
    SET_INDICATOR_DETAILS(state, details) {
      state.indicatorDetails = details
    },
    DELETE_INDICATOR(state, id) {
      state.indicatorList = [
        ...state.indicatorList.map(indicator => ({
          ...indicator,
          list: indicator.list.filter(o => o.id !== id)
        }))
      ]
    },
    SET_INDICATOR_DATA(state, data) {
      state.indicatorData = data
    },
    SET_CRITERION_LIST(state, list) {
      state.criterionList = list.sort((a, b) => a.order_number - b.order_number)
    },
    SET_CRITERION_DATA(state, data) {
      state.criterionData = data
    },
    DELETE_CRITERION(state, id) {
      state.criterionList = state.criterionList.filter(o => o.id !== id)
    },
    SET_REPORT(state, report) {
      state.report = report
    },
    SET_MAIN_REPORT(state, report) {
      state.mainReport = report
    },
  },
  getters: {},
  actions: {
    // rating actions
    getRatingList({ commit }, parent = null) {
      commit('SET_RATING_LIST', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/rating/', {
          params: {
            page_size: 999999,
            has_parent: !!parent,
            parent
          }
        }).then(response => {
          commit('SET_RATING_LIST', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    getRatingDetails({ commit }, id) {
      commit('SET_RATING_DETAILS', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`api/v1/processing/view/rating/${id}/`)
          .then(response => {
            commit('SET_RATING_DETAILS', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    getSubRatingDetails({ commit }, id) {
      commit('SET_RATING_DETAILS', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`api/v1/processing/view/subrating/${id}/`)
          .then(response => {
            commit('SET_RATING_DETAILS', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    getRatingData({ commit }, id) {
      commit('SET_RATING_DATA', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`api/v1/rating/rating/${id}/`)
          .then(response => {
            commit('SET_RATING_DATA', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    createRating(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/rating/rating/', payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    updateRating(_, { payload, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/rating/rating/${id}/`, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    deleteRating({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.delete(`api/v1/rating/rating/${id}/`)
          .then(response => {
            resolve(response.data)
            commit('DELETE_RATING', id)
          })
          .catch(error => reject(error.response))
      })
    },
    cloneRating(_, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.post(`api/v1/processing/action/rating/${id}/view/7/`)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    // rating types actions
    getRatingTypes({ commit }) {
      commit('SET_TYPES', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/rating_types/', {
          params: {
            page_size: 999999,
          }
        }).then((response) => {
          commit('SET_TYPES', response.data.results)
          resolve(response.data.results)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    // members
    getMembersList({ commit }, ratingId) {
      commit('SET_MEMBERS_LIST', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/rating_members/', {
          params: { page_size: 999999, rating: ratingId }
        }).then(response => {
          commit('SET_MEMBERS_LIST', response.data.results)
          resolve(response.data.results)  
        }).catch(error => reject(error.response))
      })
    },
    // requestions
    getRequestionsList({ commit }, ratingId) {
      commit('SET_REQUESTIONS_LIST', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/requisitions/', {
          params: { page_size: 999999, rating: ratingId }
        }).then(response => {
          commit('SET_REQUESTIONS_LIST', response.data.results)
          resolve(response.data.results)  
        }).catch(error => reject(error.response))
      })
    },
    // history
    getRatingHistory({ commit }, ratingId) {
      commit('SET_RATING_HISTORY', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/rating_history/', {
          params: { page_size: 999999, rating: ratingId }
        }).then(response => {
          commit('SET_RATING_HISTORY', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    // subindex actions
    getSubindexList({ commit }, ratingId) {
      commit('SET_SUBINDEX_LIST', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/rating_sub_indexes/', {
          params: {
            page_size: 999999,
            rating: ratingId
          }
        }).then(response => {
          commit('SET_SUBINDEX_LIST', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    getSubindexData({ commit }, id) {
      commit('SET_SUBINDEX_DATA', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`api/v1/rating/sub_index/${id}/`)
          .then(response => {
            commit('SET_SUBINDEX_DATA', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    createSubindex(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/rating/sub_index/', payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    updateSubindex(_, { payload, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/rating/sub_index/${id}/`, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    deleteSubindex({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.delete(`api/v1/rating/sub_index/${id}/`)
          .then(response => {
            resolve(response.data)
            commit('DELETE_SUBINDEX', id)
          })
          .catch(error => reject(error.response))
      })
    },
    // indicator actions
    getIndicatorList({ commit }, subindexId) {
      commit('SET_INDICATOR_LIST', { subindexId, list: [] })
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/rating_indicators/', {
          params: {
            page_size: 999999,
            sub_index: subindexId
          }
        }).then(response => {
          commit('SET_INDICATOR_LIST', { subindexId, list: response.data.results })
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    getIndicatorDetails({ commit }, id) {
      commit('SET_INDICATOR_DETAILS', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`api/v1/processing/view/rating_indicators/${id}/`)
          .then(response => {
            commit('SET_INDICATOR_DETAILS', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    getIndicatorData({ commit }, id) {
      commit('SET_INDICATOR_DATA', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`api/v1/rating/indicator/${id}/`)
          .then(response => {
            commit('SET_INDICATOR_DATA', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    createIndicator(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/rating/indicator/', payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    updateIndicator(_, { payload, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/rating/indicator/${id}/`, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    deleteIndicator({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.delete(`api/v1/rating/indicator/${id}/`)
          .then(response => {
            resolve(response.data)
            commit('DELETE_INDICATOR', id)
          })
          .catch(error => reject(error.response))
      })
    },
    // criterion actions
    getCriterionList({ commit }, indicatorId) {
      commit('SET_CRITERION_LIST', [])
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/list/rating_indicator_criterions/', {
          params: {
            page_size: 999999,
            indicator: indicatorId
          }
        }).then(response => {
          commit('SET_CRITERION_LIST', response.data.results)
          resolve(response.data.results)
        }).catch(error => reject(error.response))
      })
    },
    getCriterionData({ commit }, id) {
      commit('SET_CRITERION_DATA', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get(`api/v1/rating/indicator_criterions/${id}/`)
          .then(response => {
            commit('SET_CRITERION_DATA', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error.response))
      })
    },
    createCriterion(_, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v1/rating/indicator_criterions/', payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    updateCriterion(_, { payload, id }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`api/v1/rating/indicator_criterions/${id}/`, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    deleteCriterion({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.delete(`api/v1/rating/indicator_criterions/${id}/`)
          .then(response => {
            resolve(response.data)
            commit('DELETE_CRITERION', id)
          })
          .catch(error => reject(error.response))
      })
    },
    // reports
    getReport({ commit }, rating) {
      commit('SET_REPORT', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/raw/rating_report/', {
          params: { rating }
        }).then(response => {
          commit('SET_REPORT', response.data)
          resolve(response.data)
        }).catch(error => reject(error.response))
      })
    },
    getMainReport({ commit }, rating) {
      commit('SET_MAIN_REPORT', null)
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/processing/raw/monitoring_report/', {
          params: { rating }
        }).then(response => {
          commit('SET_MAIN_REPORT', response.data)
          resolve(response.data)
        }).catch(error => reject(error.response))
      })
    },
  },
}