export default {
  lang: "English",
  code: "EN",
  interface: {
    search: "Search",
    logout: "Log out",
    settings: "Settings",
    detail: "More",
    choose: "Choose",
    back: "Back",
    download: "Download",
    confirm_action: "Confirm action",
    remove_attention_message: "Are you sure you want to delete this object with all its contents? This action cannot be undone.",
    cancel: "Cancel",
    send: "Send",
    publish: "Publish",
    submit: "Remove ",
    save: "Save",
    remove_object: "Delete object",
    type_note: "Leave a note",
    ok: "Оk",
  },
  routes: {
    home: "Home",
    archive: "Archive",
    rating_detail: "Index",
    sub_rating_detail: "",
    requestion_detail: "Application",
    indicator_detail: "Criteria",
    events: "Calendar of events",
    manageEvents: "Events management",
  },
  user: {
    role: {
      coordinator: "Coordinator",
      expert: "Expert",
      applicant: "Applicant",
      security: "Security officer",
    }
  },
  home: {
    welcome: "Welcome",
    docs: {
      title: "Read instructions",
      desc: "Increase your efficiency",
      action: "Open instructions"
    },
    rating: {
      title: "My indexes",
      action: "Go to"
    },
    help: {
      title: "Any questions?",
      action: "Support service"
    },
    major_tasks: "Important tasks",
    notifications: "Latest events",
  },
  events: {
    manage: "Events management",
    create: "Create an event",
    created_at: "created",
    info_type: "type",
    cancel: "Cancel an event",
    cancel_attention: "Are you sure you want to cancel this event? This action cannot be undone.",
    type: {
      periodic: "Repeated event",
      exact_date: "One-time event"
    },
    status: {
      draft: "Draft",
      published: "Published",
      cancelled: "Cancelled",
    },
    period: {
      week: "Every week",
      month: "Every month",
      quarter: "Every quarter",
      "half-year": "Every half-year",
      year: "Every year",
    }
  },
  archive: {
    create: "New index"
  },
  proof: {
    create: "Add confirmation",
    proof: "Confirmations",
  },
  indicator: {
    proofs: "confirmations",
    criterions: "Responses",
    criterions_and_proofs: "Responses and confirmations",
    file: "file",
    link: "link",
    criterions_count: "scores",
    max_score: "maximum score",
    org_score: "self-assessment",
    expert_score: "expert score",
    coordinator_score: "coordinator score",
    periodicity: "",
    calculation_method: "",
  },
  criterion: {
    create: "Add response",
    score: "score",
    organization_choose: "Choice of applicant",
    expert_choose: "Choice of expert",
    coordinator_choose: "Choice of coordinator",
    reduce_score_modal: "State the reason for the score change",
  },
  history: {
    is_rejected: "Returned",
    author: "author"
  },
  rating: {
    requestions: "applications",
    created_at: "created",
    published: "published",
    open: "applications acceptance",
    evalution: "experts assessment",
    result_publish: "results publication",
    clone: "Clone",
    is_main: 'Is main',
    tabs: {
      subindex: "Indicators",
      requestions: "Applications",
      history: "History",
      report: "Summary report",
      members: "Applicants",
      subrating: "",
    }
  },
  subindex: {
    indicators_count: "indicators",
    max_score: "maximum score",
    reduce_score: "downgrade score",
    current_score: "current score",
    final_score: "final score",
    create: "Create indicator",
    org_score: "self-assessment",
    expert_score: "expert score",
    coordinator_score: "coordinator score",
    progress_by_applicant: "progress of applicant",
    progress_by_expert: "progress of experts",
    reduce_score_modal: "Reduce the score",
    annual_score: "",
    preliminary_score: "",
    reduced_annual_score: "",
  },
  report: {
    organization: "Organization",
    total_score: "Total score",
    scores: "scores"
  },
  requestion: {
    claim: "Claim",
    created_at: "created",
    published: "published",
    total_score: "total score",
    progress_by_applicant: "progress of applicant",
    progress_by_expert: "progress of experts",
    total_score: "total score",
    create_claim: "Create claim",
    status: "Application status",
    reject_scores: "",
    tabs: {
      subindex: "Indicators",
      history: "History",
    },
    statuses: {
      all: "All",
      "2": "Created",
      "3": "Submission of application",
      "4": "Moderation",
      "5": "Application evaluation",
      "6": "Score moderation",
      "7": "Application accepted",
      "8": "Not sent",
      "9": "Application score accepted",
      "10": "Summarizing results",
      "11": "Closed",
      "13": "Cancelled",
    }
  },
  settings: {
    locale: "Localization",
    locale_desc: "Change language",
    interface: "Interface",
    interface_desc: "General interface settings",
    password: "Change password",
    password_desc: "Password change",
    font_size: "Font size"
  },
  forms: {
    indicator: "Criteria",
    subindex: "Indicator",
    score: "Score",
    order_number: "Ordinal number",
    claim: "Claim",
    file: "File",
    link: "Link",
    desc_placeholder: "Enter description",
    name_placeholder: "Enter name",
    name: "Name",
    desc: "Description",
    event_type: "Event type",
    date: "Date",
    days_of_warning: "Days before event",
    repeat: "Repeat",
    notifications: "Notifications",
    daily_notifications: "Daily notifications",
    calendar_color: "Color in calendar",
    text: "Text",
    links: "Links",
    files: "Files",
    confirm_types: "Confirmation types",
    organizations: "Organizations",
    members: "Members",
    year: "Reporting period",
    index_type: "Index type",
    open_date: "Start of applications acceptance",
    close_date: "End of applications acceptance",
    evalution_open_date: "Start of experts assessment",
    evalution_close_date: "End of experts assessment",
    result_publish_date: "Results announcement date",
    reduce_score: "Downgrade score",
    short_name: "Abbreviation",
    expert_organization: "Expert organization",
    can_reduced: "Approve downgrade",
    max_reduce_score: "Maximum possible downgrade",
    index_periodicity: "",
    index_calculation_method: "",
  },
  validations: {
    required: "This field is required for completion.",
    str_min: "The field must contain at least {min} characters.",
    positive_number: "The number must be greater than or equal to zero.",
    num_min: "The number must be no less than {min}.",
    url: "Please provide a correct format link.",
    file: "You have not selected a file to upload.",
    at_least_one: "",
  },
  claim: {
    title: "Claim",
    letter: "letter of claim",
    created_at: "created",
    expert_answer: "{expert} response",
    download_attachments: "Upload attachments",
    attachments: "Attachments",
    indicators: "Complaints by criteria",
    indicator_create: "Add claim to the criterion score",
    subindexes: "Complaints by downgrade score",
    subindexes_create: "Add claim to the downgrade score",
    answer: "Answer",
    remove_answer: "Delete answer",
    org_score: "self-assesment",
    expert_score: "expert score",
    reduce_score: "downgrade score",
    indicator_modal: "Claim to criterion score",
    subindex_modal: "Claim to downgrade score",
    answer_modal: "Response to a claim",
    answer_modal_desc: "Fill out a note and attach a letter of response",
  }
};