import Vue from "vue"

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    getListMeta(_, listId) {
      return new Promise((resolve, reject) => {
        Vue.axios.get(`api/v1/processing/list/meta/${listId}/`)
          .then(response => {
            resolve(response.data)
          }).catch(error => reject(error.response))
      })
    },
    getList(_, listId) {
      return new Promise((resolve, reject) => {
        Vue.axios.get(`api/v1/processing/list/${listId}/`)
          .then(response => {
            resolve(response.data.results)
          }).catch(error => reject(error.response))
      })
    },
    doAction(_, {action, payload}) {
      return new Promise((resolve, reject) => {
        Vue.axios.post(action.action_url, payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    debug(_, url) {
      return new Promise((resolve, reject) => {
        Vue.axios.post(url)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
  }
}