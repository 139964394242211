import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import routeGuard from './routeGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/',
    name: 'home',
    beforeEnter: routeGuard,
    component: HomeView
  },
  {
    path: '/settings',
    name: 'settings',
    beforeEnter: routeGuard,
    component: () => import('../views/SettingsView.vue')
  },
  {
    path: '/events',
    name: 'events',
    beforeEnter: routeGuard,
    component: () => import('../views/EventsView.vue')
  },
  {
    path: '/manage-events',
    name: 'manageEvents',
    beforeEnter: routeGuard,
    component: () => import('../views/ManageEventsView.vue')
  },
  {
    path: '/event-create',
    name: 'createEvents',
    beforeEnter: routeGuard,
    component: () => import('../views/CreateEventsView.vue')
  },
  {
    path: '/event-edit/:id',
    name: 'editEvents',
    beforeEnter: routeGuard,
    component: () => import('../views/EditEventsView.vue')
  },
  {
    path: '/archive',
    name: 'archive',
    beforeEnter: routeGuard,
    component: () => import('../views/ArchiveView.vue')
  },
  {
    path: '/rating/:id',
    name: 'rating',
    beforeEnter: routeGuard,
    component: () => import('../views/RatingView.vue')
  },
  {
    path: '/subrating/:id',
    name: 'subrating',
    beforeEnter: routeGuard,
    component: () => import('../views/SubRatingView.vue')
  },
  {
    path: '/rating-create',
    name: 'createIndex',
    beforeEnter: routeGuard,
    component: () => import('../views/CreateRatingView.vue')
  },
  {
    path: '/subrating-edit/:id',
    name: 'editSubRating',
    beforeEnter: routeGuard,
    component: () => import('../views/EditSubRatingView.vue')
  },
  {
    path: '/rating-edit/:id',
    name: 'editIndex',
    beforeEnter: routeGuard,
    component: () => import('../views/EditRatingView.vue')
  },
  {
    path: '/subindex-create/:id',
    name: 'createSubindex',
    beforeEnter: routeGuard,
    component: () => import('../views/CreateSubindexView.vue')
  },
  {
    path: '/subindex-edit/:id',
    name: 'editSubindex',
    beforeEnter: routeGuard,
    component: () => import('../views/EditSubindexView.vue')
  },
  {
    path: '/indicator-create/:id',
    name: 'createIndicator',
    beforeEnter: routeGuard,
    component: () => import('../views/CreateIndicatorView.vue')
  },
  {
    path: '/indicator-edit/:id',
    name: 'editIndicator',
    beforeEnter: routeGuard,
    component: () => import('../views/EditIndicatorView.vue')
  },
  {
    path: '/indicator/:id',
    name: 'indicator',
    beforeEnter: routeGuard,
    component: () => import('../views/IndicatorView.vue')
  },
  {
    path: '/criterion-create/:id',
    name: 'createCriterion',
    beforeEnter: routeGuard,
    component: () => import('../views/CreateCriterionView.vue')
  },
  {
    path: '/criterion-edit/:id',
    name: 'editCriterion',
    beforeEnter: routeGuard,
    component: () => import('../views/EditCriterionView.vue')
  },
  {
    path: '/members-edit/:id',
    name: 'editMembers',
    beforeEnter: routeGuard,
    component: () => import('../views/EditMembersView.vue')
  },
  {
    path: '/requestion/:id',
    name: 'requestion',
    beforeEnter: routeGuard,
    component: () => import('../views/RequestionView.vue')
  },
  {
    path: '/requestion-indicator/:id',
    name: 'requestionIndicator',
    beforeEnter: routeGuard,
    component: () => import('../views/RequestionIndicatorView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router